<template>
  <textarea
    :id="id"
    ref="$el"
    v-model="model"
    :class="{ error }"
    :disabled="disabled"
    :placeholder="placeholder"
    :required="required"
    rows="5"
    tabindex="0"
    @blur="validate"
  />
</template>

<script setup>
// Emitter
const emit = defineEmits([
  'textarea:validate',
])

// Props
const props = defineProps({
  content: {
    default() {
      return ''
    },
    required: false,
    type: String,
  },
  disabled: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  id: {
    default() {
      return undefined
    },
    required: false,
    type: String,
  },
  placeholder: {
    default() {
      return ''
    },
    required: false,
    type: String,
  },
  required: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
})

// Refs
const $el = ref(null)
const error = ref(false)
const model = ref('')

// Methods
function validate() {
  error.value = !$el.value.checkValidity()
  emit('textarea:validate', error.value)
}

// Lifecycle Hooks
onBeforeMount(() => {
  model.value = props.content
})

// Expose
defineExpose({
  $el,
})
</script>

<style lang="postcss" scoped>
textarea {
  @apply w-full rounded border border-grey-blue-300 text-sm text-grey-blue-900 p-3 resize-none transition duration-300 ease-out;

  &.error {
    @apply border-red-600;
  }

  &:disabled {
    @apply bg-grey-blue-100 border-grey-blue-200;
  }

  &:focus {
    @apply border-livestorm-blue-700 outline-none;
  }

  &:hover {
    @apply border-grey-blue-600 outline-none cursor-pointer;
  }

  &::placeholder {
    @apply text-grey-blue-600;
  }
}
</style>
